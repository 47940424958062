<template>
  <div class="row q-gutter-sm q-mb-sm">
    <vxe-input
      v-model="BegTime"
      style="width: 100px;"
      placeholder="开始时间"
      type="time"
      size="mini"
      transfer
    ></vxe-input>
    <vxe-input
      v-model="EndTime"
      style="width: 100px;"
      placeholder="结束时间"
      type="time"
      size="mini"
      transfer
    ></vxe-input>
    <vxe-input
      v-model="Count"
      style="width: 100px;"
      placeholder="预约数量"
      type="number"
      size="mini"
    ></vxe-input>
    <vxe-button
      status="primary"
      size="mini"
      :loading="PdmBodycheckPaibantiemListLoading"
      @click="onOK"
      content="添加"
    ></vxe-button>
    <q-space />
    <!-- <q-btn color="primary" icon="download" style="height: 38px;" @click="onDownload" :loading="WxHomeimageListLoading">
                 下载
               </q-btn>
               <q-btn color="primary" style="height: 38px" icon="fas fa-file-excel" :loading="PdmBodycheckPaibantiemListLoading" @click="onExportData">导出</q-btn> -->
    <!-- <q-btn
            color="primary"
            style="height: 38px"
            icon="note_add"
            :loading="PdmBodycheckPaibantiemListLoading"
            @click="onAdd"
            >新增</q-btn
          > -->
  </div>
  <vxe-grid
    v-bind="gridOptions"
    :sort-config="{ multiple: true }"
    :custom-config="{ storage: true }"
    id="custom-config"
    height="450"
    :loading="PdmBodycheckPaibantiemListLoading"
    highlight-current-row
    size="mini"
  >
    <template #toolbar_buttons id="custom-config"> </template>
    <template #operate="{ row }">
      <!-- <vxe-button flat  color="primary" icon="fas  fa-edit" @click="onEdit(row)" >编辑</vxe-button> -->
      <vxe-button flat color="negative" size="mini" @click="onDelete(row)"
        >删除</vxe-button
      >
    </template>
  </vxe-grid>
  <!-- <vxe-pager
          perfect
          size="mini"
          :page-sizes="tablePage.pageSizes"
          v-model:current-page="tablePage.currentPage"
          v-model:page-size="tablePage.pageSize"
          :total="tablePage.totalResult"
          :layouts="[
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'FullJump',
            'Sizes',
            'Total',
          ]"
          @page-change="handlePageChange"
        >
        </vxe-pager> -->
</template>

<script>
import { mapActions, mapState } from "vuex";
import XEUtils from "xe-utils";
import dlg from "@/config/dialog";
import * as BodycheckPaibantime from "@/api/pdm/bodycheckPaibantime";
export default {
  components: {},
  computed: {
    ...mapState("PdmBodycheckPaiban", [
      "PdmBodycheckPaibanList",
      "PdmBodycheckPaibanListLoading",
      "PdmBodycheckPaibanEntity",
      "PdmBodycheckPaibanShowDlg",
    ]),
    ...mapState("PdmBodycheckPaibantiem", [
      "PdmBodycheckPaibantiemList",
      "PdmBodycheckPaibantiemListLoading",
      "PdmBodycheckPaibantiemEntity",
      "PdmBodycheckPaibantiemShowDlg",
      "PaiBanTimeShangWuList",
    ]),
  },
  data() {
    return {
      BegTime: null,
      EndTime: null,
      TimeList: [],
      Count: null,
      FilterString: "",
      tablePage: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000],
      },
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        // toolbarConfig: {
        //   perfect: true,
        //   print: true,
        //   zoom: true,
        //   custom: true,
        //   export: true,
        //   slots: {
        //     // 自定义工具栏模板
        //     buttons: "toolbar_buttons",
        //   },
        // },
        //点击编辑必须配置
        // editConfig: {
        //   trigger: "click",
        //   mode: "cell",
        //   activeMethod: () => {
        //     if (this.lockData) return false;
        //     return true;
        //   },
        // },
        tableExport: {},
        columns: [
          //   { type: "seq", width: 50, align: "center" },

          {
            field: "PaiBanTiem_BegTimeStr",
            title: "开始时间",
            sortable: true,
            align: "center",
            width: 100,
          },
          {
            field: "PaiBanTiem_EndTimeStr",
            title: "结束时间",
            sortable: true,
            align: "center",
            width: 100,
          },
          {
            field: "PaiBanTiem_Count",
            title: "预约数量",
            sortable: true,
            align: "center",
            width: 90,
          },
          {
            title: "操作",
            width: 90,
            slots: { default: "operate" },
            align: "center",
            fixed: "right",
          },
          //   {
          //     field: "CatName",
          //     title: "类别",
          //     sortable: true,
          //     editRender: { name: "input" },//点击编辑必须配置
          //     slots: { edit: "CatName" },//点击编辑必须配置
          //     align: "left",
          //     width: 140,
          //   },
        ],
        // data: this.PdmBodycheckPaibantiemList,
        data: null,
      },
    };
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    ...mapActions("PdmBodycheckPaibantiem", [
      "actCreatePdmBodycheckPaibantiem",
      "actGetPdmBodycheckPaibantiemListByPage",
      "actCreatePdmBodycheckPaibantiem",
      "actEditPdmBodycheckPaibantiemData",
      "actEditPdmBodycheckPaibantiem",
      "actDeletePdmBodycheckPaibantiem",
    ]),
    onLoadData() {
      BodycheckPaibantime.GetPaiBanTimeData({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        PaiBan_ID: this.PdmBodycheckPaibanEntity.PaiBan_ID,
      }).then((res) => {
        if (res.Code == 0) {
          this.TimeList=res.Data.ShangWuTime;
          this.$store.commit(
            "PdmBodycheckPaibantiem/setPaiBanTimeShangWuList",
            this.TimeList
          );
          this.gridOptions.data = res.Data.ShangWuTime;
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    onOK() {
      if (!this.BegTime) {
        dlg.alert("请填写开始时间");
        return;
      }
      if (!this.EndTime) {
        dlg.alert("请填写结束时间");
        return;
      }
      if (!this.Count) {
        dlg.alert("请填预约数量");
        return;
      }
      this.gridOptions.data = null;
      this.actCreatePdmBodycheckPaibantiem({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        BegTime: this.BegTime,
        EndTime: this.EndTime,
        Count: this.Count,
        PaiBanTiem_Type: 1,
      })
        .then((res) => {
          this.TimeList.push(res.Data);
          this.$store.commit(
            "PdmBodycheckPaibantiem/setPaiBanTimeShangWuList",
            this.TimeList
          );
          this.gridOptions.data = this.PaiBanTimeShangWuList;
          //console.log("1111",this.ShangWuTimeList);
        })
        .finally(() => {
          this.$store.commit(
            "PdmBodycheckPaibantiem/setPdmBodycheckPaibantiemListLoading",
            false
          );
          //this.PdmBodycheckPaibantiemListLoading = false;
        });
    },

    onDelete(e) {
      // this.TimeList = this.PaiBanTimeShangWuList.filter(
      //   (item) => item.PaiBanTiem_ID !== e.PaiBanTiem_ID
      // );
      // this.$store.commit(
      //   "PdmBodycheckPaibantiem/setPaiBanTimeShangWuList",
      //   this.TimeList
      // );
      // this.gridOptions.data = this.PaiBanTimeShangWuList;
      //console.log("222222",this.ShangWuTimeList);
      BodycheckPaibantime.DeletePDM_BodyCheck_PaiBanTime({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        PaiBanTiem_ID: e.PaiBanTiem_ID,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
          dlg.alert(res.Message);
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    //onExportData:{},
    //需要合计：表头必须添加 :footer-method="footerMethod" 跟 show-footer
    footerMethod({ columns, data }) {
      var list = ["PRIC"]; //需要汇总的字段
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (list.includes(column.property)) {
            return XEUtils.sum(data, column.property);
          }
          return null;
        }),
      ];
      return footerData;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.onLoadData();
    },
  },
};
</script>
