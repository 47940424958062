<template>
  <q-card style="width: 500px; max-width: 60vw;height: 700px;">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        排班时间编辑
        <small class="q-ml-sm text-size-sm"> </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div>
        <q-tabs
          v-model="tab"
          dense
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
          <q-tab name="shangwu" label="上午" />
          <q-tab name="xiawu" label="下午" />
        </q-tabs>
        <q-separator />
        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="shangwu">
            <ShangWu />
          </q-tab-panel>

          <q-tab-panel name="xiawu">
            <XiaWu />
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dlg from "@/config/dialog";
import ShangWu from "./paiBanTimeShangWu.vue";
import XiaWu from "./paiBanTimeXiaWu.vue";
import * as BodycheckPaibantime from "@/api/pdm/bodycheckPaibantime";
export default {
  components: {
    ShangWu,
    XiaWu,
  },
  props: {},
  computed: {
    ...mapState("PdmBodycheckPaiban", ["PdmBodycheckPaibanEntity"]),
    ...mapState("PdmBodycheckPaibantiem", [
      "PaiBanTimeShangWuList",
      "PaiBanTimeXiagWuList",
    ]),
  },
  data() {
    return {
      FilterString: "",
      tab: "shangwu",
    };
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    ...mapActions("", []),

    onLoadData() {
      this.$store.commit("PdmBodycheckPaibantiem/setPaiBanTimeShangWuList", []);
      this.$store.commit("PdmBodycheckPaibantiem/setPaiBanTimeXiagWuList", []);
    },
    onOK() {
      console.log("上午时间:", this.PaiBanTimeShangWuList);
      console.log("下午时间:", this.PaiBanTimeXiagWuList);
      BodycheckPaibantime.UpdatePaiBanTimeData2({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        PaiBan_ID: this.PdmBodycheckPaibanEntity.PaiBan_ID,
        PaiBan_Str: this.PdmBodycheckPaibanEntity.PaiBan_Str,
        PaiBanTiem_ShangWuList: this.PaiBanTimeShangWuList,
        PaiBanTiem_XiaWuList: this.PaiBanTimeXiagWuList,
      }).then((res) => {
        if (res.Code == 0) {
          this.$emit("onLoadData");
          this.$store.commit(
            "PdmBodycheckPaibantiem/setPaibantiemShowDlg",
            false
          );
          this.msg.success(res.Message);
        } else {
          dlg.alert(res.Message);
        }
      });
    },
  },
};
</script>
